import React, { useRef, useState } from 'react';

const Home = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play().then(() => {
        setIsPlaying(true);
      }).catch(error => {
        console.error('Error attempting to play video:', error);
      });
    }
  };

  return (
    <div className="relative w-full h-full min-h-screen bg-black">
      <video
        ref={videoRef}
        src="/download.mp4"
        className="absolute top-0 left-0 w-full h-full md:object-cover object-contain"
        playsInline
        loop
      >
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <button
          onClick={handlePlayClick}
          className="absolute bottom-16 md:bottom-10 left-1/2 transform -translate-x-1/2 text-white bg-blue-500 px-6 py-3 text-xl rounded"
          style={{ zIndex: 10 }}
        >
          AFSPELEN
        </button>
      )}
    </div>
  );
};

export default Home;
